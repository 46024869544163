import { eConsultationTypes } from "types/commonInterfaces";
import { getTimeZoneOffset } from "utils/commonUtils";

const API_ENDPOINTS = {
  GET_USER_PROFILE: '/users/info',
  LOGIN: '/users/login',
  SIGNUP: '/users/register',
  VERIFY_OTP: '/users/verify-otp',
  GET_CATEGORIES: '/categories?platform=web',
  GET_SUBSCRIPTION_PLANS: '/packages',
  GET_CONSULTANT_LIST: (
    currentPage: number,
    pageLimit: number,
    selectedCategory: number | null,
    filter: string | null,
    order: string | null
  ) => {
    let url = `/consultants?limit=${pageLimit}&page=${currentPage}`;
    if (selectedCategory) url += `&category_id=${selectedCategory}`;
    if (filter) url += `&filter=${filter}`;
    if (order) url += `&order=${order}`;
    if (order) url += `&column_name=${'cost_per_h'}`;
    return url;
  },
  GET_CONSULTANT_LIST_V2: (
    currentPage: number,
    pageLimit: number,
    selectedCategory: number | null,
    filter: string | null,
    order: string | null
  ) => {
    let url = `/consultants/list?limit=${pageLimit}&page=${currentPage}`;
    if (selectedCategory) url += `&category_id=${selectedCategory}`;
    if (filter) url += `&filter=${filter}`;
    if (order) url += `&order=${order}`;
    if (order) url += `&column_name=${'cost_per_h'}`;
    return url;
  },
  GET_BLOG_LIST: '/blogs',
  RESEND_OTP: '/users/resend-code',
  GET_CONSULTANT_BY_ID: (id: number,) => `/consultants/${id}`,
  GET_CONSULTANT_BY_ID_V2: (id: number) => `/consultants/detail?consultant_id=${id}`,
  GET_CONSULTATION_DETAIL_BY_CONSULTANT_ID_V2: (id: number, cnsltType: eConsultationTypes) => `/request-flow/consultation-details?consultant_id=${id}&consultation_type=${cnsltType}`,
  GET_DISTINGUISH_CONSULTANT_BY_ID: (id: number,) => `/consultants/${id}/distinguish`,
  GET_REVIEWS_CONSULTANT_BY_ID: (
    id: number,
    currentPage: number,
    pageLimit: number,
    column_name: string | null,
    order: string | null,
  ) => {
    let url = `/consultants/${id}/reviews?limit=${pageLimit}&page=${currentPage}`;
    if (column_name) url += `&column_name=${column_name}`;
    if (order) url += `&order=${order}`;
    return url;
  },
  CONSULTATIONS: '/consultations',
  CONSULTATION_V2: '/request-flow/consultation-purchased',
  GET_CONSULTATION_LIST: (currentPage: number, pageLimit: number, search: string | null, status: string | null, order: string | null, type: string | null) => {
    let url = `/consultations/index-v2?limit=${pageLimit}&page=${currentPage}`;
    if (search) url += `&search=${search}`;
    if (status) url += `&status=${status}`;
    if (order) url += `&order=${order}`;
    if (type) url += `&type=${type}`
    url += `&offset=${getTimeZoneOffset()}`
    return url;
  },
  GET_CONSULTANT_REPLIES: (id: number) => `/consultations/${id}/replies`,
  UPDATE_CONSULTANT_STATUS: `/consultations/status`,
  UPDATE_USER: (id: number) => `/users/${id}`,
  DELETE_ACCOUNT_REQUEST: (id: number) => "/users/" + id + "/delete",
  UPDATE_USER_V2: `/users/profile`,
  CHANGE_LOCALE_ON_SERVER: "/users/update-locale",
  UPDATE_USER_PASSWORD: (id: number) => `/users/${id}/password`,
  GET_NOTIFICATIONS: (currentPage: number, pageLimit: number) => {
    let url = `/notifications?limit=${pageLimit}&page=${currentPage}`;
    return url;
  },
  DELETE_CONSULTATION: (id: number) => `/consultations/${id}`,
  GET_REVIEWS_USER: (currentPage: number, pageLimit: number, column_name: string | null, order: string | null) => {
    let url = `/reviews?limit=${pageLimit}&page=${currentPage}`;
    if (column_name) url += `&column_name=${column_name}`;
    if (order) url += `&order=${order}`;
    return url;
  },
  GET_TOKEN_PAYMENT: '/payments/token',
  GET_LINK_PAYMENT: '/payments/links',
  ZOOM_MEETING: '/zoom',
  PACKAGES: '/packages',
  CONSULTATION_STATUS: '/consultations/status',
  CONSULTANTS: '/consultants?lang=',
  TRANCTIONAL_HISTORY: '/users/activities',
  TRANCTIONAL_HISTORY_V2: '/wallet',
  SOCIAL_LOGIN: 'users/social-login',
  VERIFY_EMAIL: 'users/reset-password',
  RESET_PASSWORD: 'users/reset-password',
  ACTIVE_CHATS: (id: number) => `/consultations/${id}/chat-active`,
  CANCELLED_CHATS: (id: number) => `/consultations/${id}/chat-canceled`,
  ARCHIVED_CHATS: (id: number) => `/consultations/chat-detail?consultation_id=${id}`,
  VIEW_CONSULTATION: (id: number) => `/consultations/${id}/view`,
  GET_CONSULTATION_DETAIL: (id: number) => `/consultations/${id}`,
  JOIN_ROOM: (consultation_id: number) => `/video-call`,
  sendVideoCall: () => `/consultations/start-video-call`,
  logout: () => `/users/logout`,
  GET_CALL_LOGS: (id: number) => "/consultations/" + id + "/direct-call-details",
  GET_SCHEDULES : (offset: number) => `/schedules?offset=${offset}`,
  CREATE_SCHEDULES : "/schedules",
  UPDATE_SCHEDULES : "/schedules/1",
  GET_CNSLT_SLOTS: (consultant_id: number, month: number, offset: number) => `/schedules/get-slots?consultant_id=${consultant_id}&month=${month}&offset=${offset}`,
  CHECK_COUPON: (code: string, credits: number) => `/coupons/check-coupon?code=${code}&credits=${credits}`,
  SITEMAP_GENERATOR: () => 'https://api.fasrly.com/api-v2/site-map',
  GET_FILTERED_BLOGS: (authorID: string) => `/filtered-blogs?authorID=${authorID}`
};

export default API_ENDPOINTS;
