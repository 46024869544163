import { createAsyncThunk } from '@reduxjs/toolkit';
import { iThunkApi } from '../../types/commonInterfaces';
import { iBlog } from '../../types/blogInterface';
import { apiManager } from '../../services/apiManager';
import API_ENDPOINTS from '../../contants/apiEndpoints';
import { REACT_APP_BASE_API_URL_V2 } from 'contants/envConstants';

export const getBlogListThunk = createAsyncThunk<{ blogs: iBlog[] }, { consultantId?: string }, iThunkApi>('blog/getBlogList', // name of slice
  async ({ consultantId }, thunkAPI) => {
    const state = thunkAPI.getState();

    let URI = consultantId ? API_ENDPOINTS.GET_FILTERED_BLOGS(consultantId) : API_ENDPOINTS.GET_BLOG_LIST;

    const resp = await apiManager.request(
      URI,
      {},
      'GET',
      state.user.authToken.access_token,
      // REACT_APP_BASE_API_URL_V2
    );
    if (resp.data.status === false) {
      return thunkAPI.rejectWithValue(resp.data);
    }
    return { blogs: resp.data.data };
  }
);
