export const DEFAULT_LANG = 'ar' as SUPPORTED_LANGS;

export enum SUPPORTED_LANGS {
  EN = 'en',
  AR = 'ar',
  FR = 'fr',
}

export const SUPPORTED_LANGS_ARR = Object.values(SUPPORTED_LANGS);

export enum NAV_LINK_TYPES {
  LINK = 'link',
  DROPDOWN = 'dropdown',
}

export enum REDUX_THUNK_STATUS {
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}

export const enum CONSULTANT_AVAILABILITY_STATUS {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  UNAVAILABLE = 'Unavailable',
  BUSY = 'Busy'
}

export const PAGINATION_PAGE_LIMIT = 12;
export const VISIBLE_PAGES_BUTTONS = 7;
