import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import navBarContent from '../../content/navBar.json';
import {
  getHomePageContentThunk,
  getLoginPageContentThunk,
  getNavBarContentThunk,
  getSignupPageContentThunk,
  getOtpPageContentThunk,
  getConsultantDetailPageContentThunk,
  getCategoryDetailPageContentThunk,
  getAppInfoPageContentThunk,
  getTestimonialContentThunk,
  getFaqsContentThunk,
  getConsultationPageContentThunk,
  getProfilePageContentThunk,
  getJoinAsConsultantContentThunk,
  getPrivacyPolicyContentThunk,
  getTermAndConditionContentThunk,
  getMainFaqContentThunk,
  getAppFaqsContentThunk,
  getFooterContentThunk,
  getMobileContentThunk,
  getConsultationRequestPageContent,
  getConsultationRequestReviewsContent,
  getConsultationSuccess,
  getCustomChatUI_V2,
  getCallLogsPageContentThunk,
  getProfilePageContentThunkV2,
  getHomeFaqsContentThunk
} from './content-thunks';
import {
  iHomeContent,
  iLoginContent,
  iNavbarContent,
  iSignupContent,
  iOtpContent,
  iConsultentDetailContent,
  iCategoryDetailContent,
  iAppInfoContent,
  iTestimonialContent,
  iFAQContent,
  iConsultationPageContent,
  iTermAndCondition,
  iPrivacyPolicy,
  iJoinAsConsultant,
  iProfilePage,
  iMainFaq,
  iAppFaq,
  iFooter,
  iMobileMarketing,
  iRequestPage_V2,
  iRequestReviewPage_V2,
  iConsultationSuccess_V2,
  IcustomChatUI_V2,
  ICallLogContent,
  iProfilePageV2
} from '../../types/contentInterfaces';
import { getLangFromLS } from '../../utils/storageUtils';
import { DEFAULT_LANG, SUPPORTED_LANGS } from '../../contants/commonConstants';

interface iContentStoreState {
  loginPageContent: iLoginContent;
  signUpPageContent: iSignupContent;
  navBarContent: iNavbarContent;
  homePageContent: iHomeContent;
  userLang: SUPPORTED_LANGS;
  otpPageContent: iOtpContent;
  consultDetailPageContent: iConsultentDetailContent;
  categoryDetailPageContent:iCategoryDetailContent[];
  faqsContent:iFAQContent[];
  testimonialContent:iTestimonialContent[];
  appInfoContent:iAppInfoContent;
  consultationPageContent:iConsultationPageContent;
  termAndCondition:iTermAndCondition;
  privacyPolicy:iPrivacyPolicy;
  joinAsConsultant:iJoinAsConsultant;
  profilePage:iProfilePage,
  profilePageV2: iProfilePageV2,
  mainFaq:iMainFaq,
  appfaq:iAppFaq[],
  footer:iFooter,
  mobileMarketing:iMobileMarketing,
  requestPage_V2: iRequestPage_V2,
  requestReviewPage_V2: iRequestReviewPage_V2,
  consultationSuccess: iConsultationSuccess_V2,
  CUSTOM_CHAT_UI_V2: IcustomChatUI_V2,
  callLogsContent: ICallLogContent;
  homefaq:iAppFaq[],
}

const initialState: iContentStoreState = {
  loginPageContent: {} as iLoginContent,
  signUpPageContent: {} as iSignupContent,
  homePageContent: {} as iHomeContent,
  navBarContent: navBarContent as iNavbarContent,
  userLang: getLangFromLS() || DEFAULT_LANG as SUPPORTED_LANGS,
  otpPageContent: {} as iOtpContent,
  consultDetailPageContent: {} as iConsultentDetailContent,
  categoryDetailPageContent: [],
  faqsContent:[],
  testimonialContent:[],
  appInfoContent: {} as iAppInfoContent,
  consultationPageContent: {} as iConsultationPageContent,
  termAndCondition:{} as iTermAndCondition,
  privacyPolicy:{} as iPrivacyPolicy,
  joinAsConsultant:{} as iJoinAsConsultant,
  profilePage:{} as iProfilePage,
  profilePageV2: {} as iProfilePageV2,
  mainFaq:{} as iMainFaq,
  appfaq:[],
  footer: {} as iFooter,
  mobileMarketing:{} as iMobileMarketing,
  requestPage_V2: {} as iRequestPage_V2,
  requestReviewPage_V2: {} as iRequestReviewPage_V2,
  consultationSuccess: {} as iConsultationSuccess_V2,
  CUSTOM_CHAT_UI_V2: {} as IcustomChatUI_V2,
  callLogsContent: {} as ICallLogContent,
  homefaq:[],
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    changeContentLang(state, action: PayloadAction<SUPPORTED_LANGS>) {
      state.userLang = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getConsultationRequestPageContent.fulfilled, (state, action) => {
      state.requestPage_V2 = action.payload;
    });
    builder.addCase(getConsultationRequestReviewsContent.fulfilled, (state, action) => {
      state.requestReviewPage_V2 = action.payload;
    });
    builder.addCase(getConsultationSuccess.fulfilled, (state, action) => {
      state.consultationSuccess = action.payload;
    });
    builder.addCase(getLoginPageContentThunk.fulfilled, (state, action) => {
      state.loginPageContent = action.payload;
    });
    builder.addCase(getSignupPageContentThunk.fulfilled, (state, action) => {
      state.signUpPageContent = action.payload;
    });
    builder.addCase(getNavBarContentThunk.fulfilled, (state, action) => {
      state.navBarContent = action.payload;
    });
    builder.addCase(getHomePageContentThunk.fulfilled, (state, action) => {
      state.homePageContent = action.payload;
    });
    builder.addCase(getOtpPageContentThunk.fulfilled, (state, action) => {
      state.otpPageContent = action.payload;
    });
    builder.addCase(getConsultantDetailPageContentThunk.fulfilled, (state, action) => {
      state.consultDetailPageContent = action.payload;
    });
    builder.addCase(getCategoryDetailPageContentThunk.fulfilled, (state, action) => {
      state.categoryDetailPageContent = action.payload;
    });
    builder.addCase(getAppInfoPageContentThunk.fulfilled, (state, action) => {
      state.appInfoContent = action.payload;
    });
    builder.addCase(getTestimonialContentThunk.fulfilled, (state, action) => {
      state.testimonialContent = action.payload;
    });
    builder.addCase(getFaqsContentThunk.fulfilled, (state, action) => {
      state.faqsContent = action.payload;
    });
    builder.addCase(getConsultationPageContentThunk.fulfilled, (state, action) => {
      state.consultationPageContent = action.payload;
    });
    builder.addCase(getProfilePageContentThunk.fulfilled, (state, action) => {
      state.profilePage = action.payload;
    });
    builder.addCase(getProfilePageContentThunkV2.fulfilled, (state, action) => {
      state.profilePageV2 = action.payload;
    });
    builder.addCase(getJoinAsConsultantContentThunk.fulfilled, (state, action) => {
      state.joinAsConsultant = action.payload;
    });
    builder.addCase(getPrivacyPolicyContentThunk.fulfilled, (state, action) => {
      state.privacyPolicy = action.payload;
    });
    builder.addCase(getTermAndConditionContentThunk.fulfilled, (state, action) => {
      state.termAndCondition = action.payload;
    });
    builder.addCase(getAppFaqsContentThunk.fulfilled, (state, action) => {
      state.appfaq = action.payload;
    });
    builder.addCase(getHomeFaqsContentThunk.fulfilled, (state, action) => {
      state.homefaq = action.payload;
    });
    builder.addCase(getMainFaqContentThunk.fulfilled, (state, action) => {
      state.mainFaq = action.payload;
    });
    builder.addCase(getFooterContentThunk.fulfilled, (state, action) => {
      state.footer = action.payload;
    });
    builder.addCase(getMobileContentThunk.fulfilled, (state, action) => {
      state.mobileMarketing = action.payload;
    });
    builder.addCase(getCustomChatUI_V2.fulfilled, (state, action) => {
      state.CUSTOM_CHAT_UI_V2 = action.payload;
    });
    builder.addCase(getCallLogsPageContentThunk.fulfilled, (state, action) => {
      state.callLogsContent = action.payload;
    });
  },
});
export const { changeContentLang } = contentSlice.actions;
export default contentSlice.reducer;
